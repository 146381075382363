import { Context } from '@nuxt/types'
import { HTTP_REST_FRONTEND_API } from '../plugins/axiosFrontendApi'
import { ThemeType } from '../contracts/Settings'
import { PageHideNavigation } from '../contracts/Page'
import { searchVacanciesRequest } from './searchVacanciesRequest'
import { searchDataRequest } from './searchDataRequest'
import { Logger } from './Logger'

export const pageRequest = async (ctx: Context) => {
  const {
    app,
    route: { path, query },
    error,
    redirect,
    store: { getters, dispatch, commit },
    $config,
  } = ctx
  // формируем урл для запрос данных страницы с frontend api
  const reqUrl = $config.SM_API_GET_PAGE_STATE_PATH + path
  try {
    // запрос на frontend api
    const res = await HTTP_REST_FRONTEND_API(ctx).get(reqUrl)
    const content = res.data

    if (content.redirect && content.redirect.length) {
      return redirect(301, content.redirect)
    }

    if (content.error) {
      error({ statusCode: 404 }) // если есть ошибка выкидываем 404
      // уведомление страницы
      commit('settings/SET_NOTIFICATION', undefined)
    } else {
      // данные полученные с frontend api
      let setThemeOnClient = false
      const {
        breadcrumbs,
        isSearchPage,
        isSearchVacanciesPage,
        showButtonBuyOnline,
        components: pageComponents,
        theme = ThemeType.primary,
        notification,
        hideNavigation,
      } = content

      if (process.server) {
        commit('settings/SET_THEME', theme)
      } else {
        setThemeOnClient = true
      }
      // записываем данные для хлебных крошек
      dispatch('breadcrumbs/create', breadcrumbs || [])

      // показать или не показать сквозную кнопку покупки оналйн
      commit('popupBuyOnline/SET_SHOW_BUTTON', !!showButtonBuyOnline)

      // уведомление страницы
      commit('settings/SET_NOTIFICATION', notification)

      // если на сайт зашли с webview, то скрываем футер и хедер
      const isWebview = query.client === 'emp'
      commit('settings/SET_HIDE_FOOTER', isWebview)
      commit('settings/SET_HIDE_HEADER', isWebview)
      // Скрыть навигацию
      if (hideNavigation === PageHideNavigation.ALL) {
        commit('settings/SET_HIDE_FOOTER', true)
        commit('settings/SET_HIDE_HEADER', true)
      }

      if (hideNavigation === PageHideNavigation.FOOTER) {
        commit('settings/SET_HIDE_FOOTER', true)
      }

      if (hideNavigation === PageHideNavigation.HEADER) {
        commit('settings/SET_HIDE_HEADER', true)
      }

      // скрыть все компоненты на странице для определенного iframe блока
      const isIframeOnClearPage = pageComponents.find(
        (component) =>
          component.type === 'sectionIframe' && component.value.clearPage
      )
      if (isIframeOnClearPage) {
        commit('settings/SET_HIDE_FOOTER', true)
        commit('settings/SET_HIDE_HEADER', true)
        commit('settings/SET_HIDE_SURVEY_BTN', true)
      }

      // обработчик запроса поиска вакансий
      if (isSearchVacanciesPage) {
        try {
          const { components } = await searchVacanciesRequest(
            query,
            app,
            pageComponents
          )
          return { ...content, components }
        } catch (e) {
          Logger.error(e)
          return {
            ...content,
            theme,
            setThemeOnClient,
            components: pageComponents.map((component) => {
              if (
                [
                  'sectionSearchVacancies',
                  'sectionSearchVacanciesStartCareer',
                ].includes(component.type)
              ) {
                return {
                  ...component,
                  value: {
                    ...component.value,
                    initialListVacancies: [],
                    listRegions: [],
                    listSpecialties: [],
                    initialTotalCountVacancies: 0,
                    searchError: true,
                  },
                }
              }
              return component
            }),
          }
        }
      } else if (
        isSearchPage &&
        query.search &&
        typeof query.search === 'string'
      ) {
        try {
          const { components } = await searchDataRequest(
            app,
            getters,
            query.search,
            pageComponents
          )
          return { ...content, components, theme, setThemeOnClient }
        } catch (e) {
          Logger.error(e)
          return {
            components: pageComponents.map((component) => {
              if (component.type === 'sectionSearch') {
                return {
                  ...component,
                  value: {
                    ...component.value,
                    result: [],
                    totalCount: 0,
                    searchError: true,
                  },
                }
              }
              return component
            }),
          }
        }
      }
      return {
        ...content,
        theme,
        setThemeOnClient,
      }
    }
    // обработчик ошибки
  } catch (e) {
    error({ statusCode: e?.response?.status || 500 })
  }
}
